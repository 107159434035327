import React from 'react';
import Button from './Button';

const Card = ({ headline, subHeadline, showSubHeadline, showButton, buttonText, onClick }) => (
  <div className={'bg-blanc shadow-xl mx-10 md:mx-0 max-w-md h-auto flex justify-center items-center rounded-lg'}>
    <div className={'flex flex-col justify-evenly py-8 px-8'}>
      <div className={'text-xl xs:text-3xl text-gray lg:text-4xl font-bold'}>{headline}</div>
      {showSubHeadline && (
        <div className={'text-sm xs:text-xl text-gray lg:text-2xl mt-4'}>
          <span>{subHeadline}</span>
        </div>
      )}

      <div className={'mt-4 xs:mt-8 md:mt-4'}>
        {showButton && (
          <div onClick={onClick}>
            <Button type={'primary'}>{buttonText}</Button>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default Card;
