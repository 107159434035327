import card1 from '../assets/memory/gsk_marke_pk_mehralsgeld_eichhoernchen_Memory.jpg';
import card2 from '../assets/memory/gsk_marke_pk_mehralsgeld_eismeer_Memory.jpg';
import card3 from '../assets/memory/gsk_marke_pk_mehralsgeld_faust_Memory.jpg';
import card4 from '../assets/memory/gsk_marke_pk_mehralsgeld_fraumitkind_Memory.jpg';
import card5 from '../assets/memory/gsk_marke_pk_mehralsgeld_torte_Memory.jpg';
import card6 from '../assets/memory/gsk_marke_pk_mehralsgeld_zunge_Memory.jpg';

const introCardObject = [
  {
    id: 1,
    type: 'card1',
    img: card1,
  },
  {
    id: 2,
    type: 'card2',
    img: card2,
  },
];

const cardObject = [
  {
    id: 1,
    type: 'card1',
    img: card1,
  },
  {
    id: 2,
    type: 'card2',
    img: card2,
  },
  {
    id: 3,
    type: 'card3',
    img: card3,
  },
  {
    id: 4,
    type: 'card4',
    img: card4,
  },
  {
    id: 5,
    type: 'card5',
    img: card5,
  },
  {
    id: 6,
    type: 'card6',
    img: card6,
  },
];

function shuffleDeck(cards) {
  const shuffledCards = cards.slice(0);

  for (let i = 0; i < cards.length; i += 1) {
    const randomIndex = Math.floor(Math.random() * (i + 1));
    const temp = shuffledCards[i];
    shuffledCards[i] = shuffledCards[randomIndex];
    shuffledCards[randomIndex] = temp;
  }

  return shuffledCards;
}

const initializeDeck = (isIntro) => {
  const cards = [];
  let idPair = 7;

  if (isIntro === true) {
    introCardObject.forEach(({ id, img, type }) => {
      cards.push({ id, img, type });
      cards.push({ id: idPair, img, type });

      idPair += 1;
    });
  } else {
    cardObject.forEach(({ id, img, type }) => {
      cards.push({ id, img, type });
      cards.push({ id: idPair, img, type });

      idPair += 1;
    });
  }

  return shuffleDeck(cards);
};

export default initializeDeck;
