import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import background from '../../assets/background.svg';
import beach from '../../assets/strandkorb.svg';
import { Button } from '../widgets';

function Welcome() {
  const history = useHistory();
  const routeToGame = () => {
    history.push('/game');
  };

  return (
    <div className={'page bg-primary'}>
      <div className={'flex flex-col w-screen h-full md:h-screen relative bg-primary'}>
        <div className={'flex flex-col mx-auto md:h-screen md:max-w-4xl xl:max-w-5xl relative '}>
          <div className={'h-1/5 bg-secondary mx-auto my-4 xxs:my-16 xs:my-6 md:my-16 xl:my-32'}>
            <div>
              <img alt={'logo'} src={logo} />
            </div>
          </div>

          <div
            className={
              'flex flex-col items-center justify-around md:items-stretch mx-10 md:mx-10 lg:mx-0 md:flex-row z-10'
            }>
            <img alt={'beach chair'} src={beach} />

            <div className={'flex flex-col justify-evenly mt-6 md:mt-0 md:ml-20'}>
              <p className={'text-2xl xs:text-3xl md:text-4xl font-bold text-gray'}>
                Gewinne deinen exklusiven Hin&VIP-Strandkorb!
              </p>

              <span className={'text-md xs:text-xl md:text-2xl mt-2 text-gray'}>
                Finde innerhalb der vorgegebenen Zeit alle Memory&#174;-Paare, um an der Verlosung für ein OpenAir Event im
                SparkassenPark Mönchengladbach teilzunehmen.
              </span>

              <div className={'mt-4 mb-4 md:mb-4'} onClick={routeToGame}>
                <Button type={'primary'}>Spiel starten</Button>
              </div>
            </div>
          </div>

          <div className={'absolute mt-32'}>
            <img alt={'background'} src={background} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Welcome);
