import React from 'react';
import { withRouter } from 'react-router-dom';
import background from '../../assets/background.svg';
import logo from '../../assets/logo.svg';
import beach from '../../assets/strandkorb.svg';
import { Button } from '../widgets';

function Success() {
  /*
  const participate = () => {
    window.top.location.href = 'https://sparkassenpark.de/hin-und-vip/teilnahme-hin-und-vip/';
  };
   */

  return (
    <div className={'page bg-primary'}>
      <div className={'flex flex-col w-screen h-full md:h-screen relative bg-primary'}>
        <div className={'flex flex-col mx-auto md:h-screen max-w-5xl relative '}>
          <div className={'h-1/5 bg-secondary mx-auto my-6 xs:my-10 md:my-16 lg:my-20 xl:my-32'}>
            <div>
              <img alt={'logo'} src={logo} />
            </div>
          </div>

          <div className={'flex flex-col items-center justify-around xl:items-stretch mx-10 md:mx-0 lg:flex-row z-10'}>
            <img alt={'beach chair'} src={beach} />

            <div className={'flex flex-col justify-evenly mt-10 md:mt-10 xs:max-w-md md:ml-20'}>
              <p className={'text-2xl xs:text-3xl md:text-4xl font-bold text-gray'}>
                Glückwunsch, du hast es geschafft!
              </p>

              <span className={'text-md xs:text-xl md:text-2xl mt-2 text-gray'}>
                Wenn du dein Können bei weiteren Spielen der Sparkasse unter Beweis stellen möchtest, klicke einfach auf
                den folgenden Button!
              </span>

              <div
                className={'mt-4 mb-4 md:mb-4'}
                onClick={window.open('https://www.kaiserkom.com/online-games/', '_blank')}>
                <Button type={'primary'}>Weitere Spiele</Button>
              </div>
            </div>
          </div>

          <div className={'absolute mt-32'}>
            <img alt={'background'} src={background} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Success);
