import React from 'react';

const ButtonType = {
  primary: 'bg-transparent hover:bg-red text-red hover:text-white border-2 border-red font-bold rounded-full',
};

const Button = ({ type, children }) => {
  const classNames = `${ButtonType[type]} py-1 px-3 text-md xs:py-2 xs:px-8 xs:text-lg focus:outline-none`;

  return <button className={classNames}>{children}</button>;
};

export default Button;
