import React, { useEffect, useState } from 'react';
import { Card } from '../../widgets';
import MemoryCardList from './MemoryCardList';

const Game = () => {
  const [hideOverlay, setHideOverlay] = useState(false);
  const [isOpening, setIsOpening] = useState(true);
  const [showTimer, setShowTimer] = useState(false);
  const [firstCardClicked, setFirstCardClicked] = useState(false);
  const [isIntro, setIsIntro] = useState(true);
  const [seconds, setSeconds] = useState(60);
  const [mobileStyling, setMobileStyling] = useState([]);

  const handleClick = () => {
    if (isOpening) {
      setIsOpening(false);
      setHideOverlay(true);
      setMobileStyling('order-2');
    }
  };

  const handleCallback = (timer) => {
    setShowTimer(timer);
  };

  const handleFirstCardClicked = (clicked) => {
    setFirstCardClicked(clicked);
  };

  const handleIntro = (intro) => {
    setTimeout(() => {
      setHideOverlay(intro);
      setIsIntro(intro);
    }, 2000);
  };

  const handleClick2 = () => {
    setHideOverlay(true);
    setShowTimer(true);
  };

  const startOver = () => {
    setHideOverlay(true);
    setSeconds(60);
    setShowTimer(true);
  };

  /* eslint-disable consistent-return */
  useEffect(() => {
    if (showTimer) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else if (seconds === 0) {
          setHideOverlay(false);
          setShowTimer(false);
          clearInterval(interval);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  });

  return (
    <div className={'page bg-secondary'}>
      <div className={'bg-secondary w-screen h-full relativ'}>
        {!hideOverlay && (
          <div className={'bg-secondary opacity-50 w-screen h-screen backdrop-filter backdrop-blur-lg absolute z-10'} />
        )}

        <div
          className={
            'flex flex-col xl:flex-row mx-auto h-screen md:max-w-7xl justify-evenly items-center bg-secondary'
          }>
          <div className={`xl:order-first ${mobileStyling}`}>
            <MemoryCardList
              firstCardClicked={handleFirstCardClicked}
              isIntro={handleIntro}
              startOver={seconds === 0}
              timer={handleCallback}
            />
          </div>

          {isIntro ? (
            <div className={'flex justify-center z-20 w-full lg:w-1/3'}>
              {isOpening ? (
                <Card
                  buttonText={'Testrunde starten'}
                  headline={'Wir starten mit einer Testrunde – los geht’s!'}
                  onClick={handleClick}
                  showButton
                />
              ) : (
                <div
                  className={
                    'flex mx-auto justify-center text-gray font-bold text-2xl xs:text-3xl md:text-4xl max-w-md'
                  }>
                  {firstCardClicked ? (
                    <span className={'w-56 h-1/4 text-center xl:text-left md:w-full'}>Finde das gleiche Motiv!</span>
                  ) : (
                    <span className={'w-56 h-1/4 text-center xl:text-left md:w-full'}>
                      Klicke die Karten an, um sie zu drehen.
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              className={`z-20 ${
                showTimer ? ' ' : 'absolute bottom-1 xl:static'
              } flex justify-center lg:w-full xl:w-1/5`}>
              {showTimer ? (
                <div className={'text-gray font-bold text-2xl xs:text-3xl md:text-4xl max-w-sm'}>
                  {seconds < 10 ? 0 : ''}
                  {seconds} Sekunden übrig
                </div>
              ) : (
                <Card
                  buttonText={seconds === 0 ? 'Neuer Versuch' : 'Kein Problem!'}
                  headline={seconds === 0 ? 'Die Zeit ist leider abgelaufen.' : 'Super, das klappt schon mal gut!'}
                  onClick={seconds === 0 ? startOver : handleClick2}
                  showButton
                  showSubHeadline
                  subHeadline={
                    seconds === 0
                      ? 'Versuche es nochmal, um an der Verlosung für ein OpenAir-Event teilzunehmen.'
                      : 'Schaffst du es auch mit 12 Karten in 60 Sekunden?'
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Game;
