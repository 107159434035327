import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import initializeDeck from '../../../utils/initializeDeck';
import MemoryCardItem from './MemoryCardItem';

const MemoryCardList = ({ timer, firstCardClicked, isIntro, startOver }) => {
  const [cards, setCards] = useState([]);
  const [intro, setIsIntro] = useState(true);
  const [flipped, setFlipped] = useState([]);
  const [matched, setMatched] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setCards(initializeDeck(intro));
  }, [intro]);

  useEffect(() => {
    cards.forEach((card) => {
      new Image().src = card.img;
    });
  }, [cards]);

  useEffect(() => {
    setMatched([]);
    setFlipped([]);
  }, [startOver]);

  const sameCardClicked = (id) => flipped.includes(id);

  const isMatch = (id) => {
    const clickedCard = cards.find((card) => card.id === id);
    const flippedCard = cards.find((card) => card.id === flipped[0]);

    return flippedCard.type === clickedCard.type;
  };

  const resetCards = () => {
    setFlipped([]);
    setDisabled(false);
  };

  const isFinished = () => {
    if (intro && matched.length === cards.length / 2) {
      isIntro(false);
      setTimeout(() => {
        resetCards();
        setMatched([]);
        setIsIntro(false);
      }, 2000);
    } else if (!intro && matched.length === cards.length - 2) {
      setTimeout(() => {
        history.push('/success');
      }, 2000);
    }
  };

  const handleClick = (id) => {
    if (!intro) {
      timer(true);
    } else {
      firstCardClicked(true);
    }
    setDisabled(true);

    if (flipped.length > 0) {
      if (sameCardClicked(id)) {
        setDisabled(false);
      } else {
        setFlipped([flipped[0], id]);
        if (isMatch(id)) {
          setMatched([...matched, flipped[0], id]);
          isFinished();
          resetCards();
        } else {
          setTimeout(resetCards, 1000);
        }
      }
    } else {
      setFlipped([id]);
      setDisabled(false);
    }
  };

  const memoryCardItems = cards.map((card) => (
    <MemoryCardItem
      backImage={card.img}
      disabled={disabled || matched.includes(card.id)}
      flipped={flipped.includes(card.id)}
      handleClick={handleClick}
      id={card.id}
      isIntro={intro}
      key={card.id}
      matched={matched.includes(card.id)}
    />
  ));

  return (
    <div>
      {intro ? (
        <div
          className={
            'flex justify-evenly items-center flex-wrap mt-6 xl:ml-10 max-w-md xxs:max-w-xs xs:max-w-md md:max-w-xl xs:px-16 xl:px-0'
          }>
          {memoryCardItems}
        </div>
      ) : (
        <div
          className={
            'flex justify-evenly items-center flex-wrap mt-6 xl:ml-10 transition-all duration-500 ease-in-out max-w-md md:max-w-2xl lg:max-w-4xl xl:max-w-6xl'
          }>
          {memoryCardItems}
        </div>
      )}
    </div>
  );
};

export default MemoryCardList;
