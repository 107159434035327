import React from 'react';
import teaser from '../../../assets/strandkorb-card.svg';

const MemoryCardItem = ({ id, backImage, flipped, handleClick, matched, disabled, isIntro }) => {
  const basicStyle = 'bg-blanc transition-transform';
  const flippedStyle = 'bg-blanc transition-transform animate-flip';

  return (
    <div
      className={`shadow-xl relative ${
        isIntro ? 'w-32 h-32' : 'w-h-border'
      } xs:w-44 xs:h-44 md:w-48 md:h-48 xl:w-64 xl:h-64 flex justify-center items-center rounded-lg mb-4 ${
        flipped || matched ? flippedStyle : basicStyle
      } `}
      onClick={() => (disabled ? null : handleClick(id))}>
      {flipped || matched ? (
        <div
          className={`bg-primary ${
            isIntro ? 'w-h-card-intro' : 'w-h-card'
          } xs:w-36 xs:h-36 md:w-40 md:h-40 xl:w-56 xl:h-56 flex justify-center filter blur-sm`}>
          <img className={'filter blur-md back-hidden'} alt={'card-background'} src={backImage} />
        </div>
      ) : (
        <div
          className={`bg-primary ${
            isIntro ? 'w-h-card-intro' : 'w-h-card'
          } xs:w-36 xs:h-36 md:w-40 md:h-40 xl:w-56 xl:h-56 flex justify-center p-4 filter blur-sm`}>
          <img className={'filter blur-md'} alt={'card-background'} src={teaser} />
        </div>
      )}
    </div>
  );
};

export default MemoryCardItem;
