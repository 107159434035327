import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Game, Success, Welcome } from '../pages';

export default function App() {
  const nodeRef = React.useRef(null);

  return (
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={1000} classNames={'fade'} nodeRef={nodeRef}>
              <Switch location={location}>
                <Route exact path={'/'} component={Welcome} />
                <Route path={'/game'} component={Game} />
                <Route path={'/success'} component={Success} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
  );
}
